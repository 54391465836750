<template>
	<div class="appMain">
		<web-header></web-header>
		<div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" :list1="menuList[2]" />
		</div>
		<div class="content">
			<!-- <div class="contentTop">第一章</div> -->
			<div class="contentBottom">
				<div class="answer">答题情况({{correctCount+mistakeCount}}/{{topic.length}})</div>
				<div class="bottomTitle">
					<p>
						<span></span>正确({{correctCount}})
					</p>
					<p>
						<span></span>错误({{mistakeCount}})
					</p>
					<p>
						<span></span>未做({{defaultCount}})
					</p>
				</div>
				<div class="bottomContent">
					<span v-for="(it,i) in topic" :key="i"
						:class="it.stuAnswer === '1'? 'success':(it.stuAnswer==='2'? 'error':'')">{{i+1}}</span>
				</div>
				<div class="bottomFoot">
					<el-button @click="seeWrong">查看错题</el-button>
					<el-button @click="reExamination">重新答题</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	export default {
		components: {
			webHeader,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "培训项目", "练习结果"],
				getDataList: '',
				// 得到的题目
				topic: [],
				correctCount: 0,
				mistakeCount: 0,
				defaultCount: 0,
				chapterId: '', //章节ID
				courseId: '', //课程ID  
			}
		},
		created() {
			this.chapterId = localStorage.getItem('zjieId')
			this.courseId = localStorage.getItem('coureId')
			// 课后练习题的即可
			this.$http.post('mineTest', {
				chapterId: this.chapterId,
				courseId: this.courseId
			}).then(res => {
				if (res.code == 200) {
					let data = res.data;
					let topic = [];
					for (let i in data) {
						if (data[i]) {
							topic.push(...data[i]);
						}
					}
					let correctCount = 0,
						mistakeCount = 0,
						defaultCount = 0;
					if (topic) {
						topic.forEach(item => {
							if (item.stuAnswer === item.answer) {
								item.stuAnswer = '1';
								correctCount++
							} else if (!item.stuAnswer) {
								item.stuAnswer = '3';
								defaultCount++
							} else {
								item.stuAnswer = '2';
								mistakeCount++
							}
						});
					}
					this.topic = topic;
					this.correctCount = correctCount;
					this.mistakeCount = mistakeCount;
					this.defaultCount = defaultCount;
				}
			})
		},
		methods: {
			seeWrong() {
				this.$router.push({
					path: '/personalCenter',
					query: {
						index: 6,
						infoIndex: 4
					}
				})
			},
			reExamination() {
				this.$router.push({
					path: '/examination/afterClassTest',
					query: {
						again: 1
					}
					// query: {
					// 	index: 6,
					// 	infoIndex: 1
					// }
				})
			}
		}

	}
</script>

<style lang="less" scoped>
	.appMain {
		background: #eee;
		padding-bottom: 50px;
	}

	.main {
		width: 1200px;
		margin: auto;
	}

	.content {
		width: 1200px;
		height: 696px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		margin: 0 auto;
		padding: 22px 69px;

		.contentTop {
			height: 51px;
			background: #EEEEEE;
			margin: 0 auto;
			line-height: 51px;
			padding-left: 75px;
			font-size: 24px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333333;
		}

		.contentBottom {
			margin-left: 130px;

			.answer {
				margin-top: 18px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
			}

			.bottomTitle {
				margin-top: 50px;
				display: flex;

				p {
					display: flex;
					margin-right: 80px;

					span {
						width: 17px;
						height: 17px;
						background: #24BE27;
						display: block;
						margin-top: 6px;
						margin-right: 10px;
					}
				}

				p:nth-child(2) {
					span {
						background: #E51E0B;
					}
				}

				p:nth-child(3) {
					span {
						border: 1px solid #EEEEEE;
						background: #fff;
					}
				}
			}

			.bottomContent {
				display: flex;
				flex-wrap: wrap;
				margin-top: 40px;

				span {
					display: block;
					width: 50px;
					height: 50px;
					border: 1px solid #EEEEEE;
					text-align: center;
					line-height: 50px;
					margin-right: 10px;
					margin-bottom: 10px;
				}

				.error {
					background: #E51E0B;
					color: #fff;
				}

				.success {
					background: #24BE27;
					color: #fff;
				}
			}

			.bottomFoot {
				margin-top: 50px;

				button {
					width: 94px;
					height: 42px;
					color: #fff;
				}

				button:nth-child(1) {
					background: #E51E0B;
				}

				button:nth-child(2) {
					background: #29A6E6;
				}
			}
		}
	}
</style>
